import { env } from "$env/dynamic/public"
import { PUBLIC_RELEASE } from "$env/static/public"
import * as Sentry from "@sentry/sveltekit"

Sentry.init({
  dsn: "https://df6fc095c5c94ed2a72544e2a7e3d22d@o1114877.ingest.sentry.io/6146354",
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // In development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // Sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [ new Sentry.Replay() ],
  enabled: env.PUBLIC_SENTRY_ENVIRONMENT !== "dev",
  environment: env.PUBLIC_SENTRY_ENVIRONMENT,
  release: PUBLIC_RELEASE ?? "develop",
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry()
